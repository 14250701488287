import Notice from 'components/ui/notice'

import { formatAmount } from 'utils/formatters'

import { IExchangeData } from 'store/fetchers/loans/enum'
import { ICurrency } from 'store/fetchers/currencies'

import { ReactElement } from 'react'
import cn from 'classnames'

import percentStyles from '../ltv-option/styles.module.scss'

import styles from './styles.module.scss'

export default function TermsBlock({
  exchange,
  to,
  from,
}: {
  exchange: IExchangeData
  to: ICurrency
  from: ICurrency
}): ReactElement {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsItem}>
        <div className={styles.termsName}>
          <p>Loan Term</p>
          <Notice alt={'Borrow Bitcoin and other cryptocurrencies'}>
            The loan term depends only on your wish to buy your collateral back and close this loan or on reaching the
            liquidation limit.
          </Notice>
        </div>
        <div className={styles.termsValue}>Unlimited</div>
      </div>
      <div className={styles.termsItem}>
        <div className={styles.termsName}>
          <p>Monthly Interest</p>
          <Notice alt={'how to borrow cryptocurrency'}>
            Interest rate is accrued every month from the moment of getting the loan and is paid at the moment of full
            or partial repayment of the loan
          </Notice>
        </div>
        <div className={styles.termsValue}>
          <div className={cn(percentStyles.ltvOption, percentStyles.ltvOptionSelected)}>
            <span
              className={cn(
                styles.termsValue__text_inactive,
                styles.termsValue__text_space_right,
                styles.termsValue__text_hidden,
              )}
            >
              27%
            </span>
            <span className={cn(styles.termsValue__text_highlighted)}>{exchange?.interest_percent}% APR</span>
          </div>
          <div>{formatAmount(exchange?.interest_amounts?.month, to, to?.is_stable ? 2 : to?.decimal_places, true)}</div>
        </div>
      </div>
    </div>
  )
}
