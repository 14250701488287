import api from 'config/api'
import Error from 'next/error'

export interface IFetchCaptcha {
  success: number
  challenge: string
  gt: string
  new_captcha: boolean
}

export const EMessageTypes = {
  CONFIRM_LOAN_CREATE: 'CONFIRM_LOAN_CREATE',
  CONFIRM_LOAN_REPAYMENT: 'CONFIRM_LOAN_REPAYMENT',
  CONFIRM_EARN_CREATE: 'CONFIRM_EARN_CREATE',
  CONFIRM_EARN_WITHDRAW: 'CONFIRM_EARN_WITHDRAW',
  MONITOR_LIST: 'MONITOR_LIST',
  SECOND_CREDENTIAL: 'SECOND_CREDENTIAL',
  TFA_ACTIVATION: 'TFA_ACTIVATION',
  TFA_DEACTIVATION: 'TFA_DEACTIVATION',
  NORA_CONTROL_WITHDRAWAL: 'NORA_CONTROL_WITHDRAWAL',
  NORA_CONTROL_CONVERSION: 'NORA_CONTROL_CONVERSION',
  COLLATERAL_RETURN: 'COLLATERAL_RETURN',
  CONFIRM_WITHDROWAL: 'CONFIRM_WITHDROWAL',
  CONFIRM_WHITELIST_ADDING: 'CONFIRM_WHITELIST_ADDING',
  NORA_CONTROL_TRANSFER: 'NORA_CONTROL_TRANSFER',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_EMAIL_NEW_EMAIL: 'CHANGE_EMAIL_NEW_EMAIL',
  CHANGE_PHONE: 'CHANGE_PHONE',
  AUTOINCREASE_SWITCH: 'AUTOINCREASE_SWITCH',
  FALLBACK_REMOVE_2FA: 'FALLBACK_REMOVE_2FA',
  FALLBACK_REMOVE_2FA_BACKUP: 'FALLBACK_REMOVE_2FA_BACKUP',
  SET_FALLBACK_EMAIL: 'SET_FALLBACK_EMAIL',
  SET_FALLBACK_EMAIL_NEW_EMAIL: 'SET_FALLBACK_EMAIL_NEW_EMAIL',
  NORA_CONTROL_PROCESS_DOUBLE_DEPOSIT: 'NORA_CONTROL_PROCESS_DOUBLE_DEPOSIT',
  NORA_CONTROL_EXCHANGE_REFUND: 'NORA_CONTROL_EXCHANGE_REFUND',
} as const

interface Withdrawal {
  loanId?: string
  amount?: string | number
  currency?: string
  payoutAddress?: string
  payoutExtraId?: string
  externalId?: string
  feePaidBy?: string
  fromCurrency?: string
  fromNetwork?: string
  toCurrency?: string
  toNetwork?: string
  fromAmount?: number
  address?: string
  extraId?: string
  type?: string
  flow?: string
}

interface Conversion {
  fromAmount: string
  fromCurrency: string
  toCurrency: string
  from: string
  to: string
}

interface Refund {
  sub_id?: string
  exchange_id?: string
  to?: string
  amount?: string
  currency?: string
}

interface Transfer {
  from: string
  to: string
  amount: string
  currency: string
  externalId?: string
}

interface DoubleDeposit {
  sub_id: string
  parent_id: string
  is_related: boolean
}

interface SendSlackNotificationDto {
  message: string
  channel: string
}

export const sendVerificationCode = async ({
  loan_id,
  earn_id,
  phone,
  email,
  newEmail,
  newPhone,
  type,
  captcha,
  withdrawal,
  overwrite,
  conversion,
  transfer,
}: {
  loan_id?: string
  earn_id?: string
  phone?: string
  email?: string
  newEmail?: string
  newPhone?: string
  type: typeof EMessageTypes[keyof typeof EMessageTypes]
  captcha: string
  withdrawal?: Withdrawal
  conversion?: Conversion
  overwrite?: any
  transfer?: Transfer
}): Promise<{
  tokens: string[]
  isActive2fa: boolean
  error?: string
}> => {
  try {
    const { data } = await api.post('/v2/utils/verification-code/send', {
      params: {
        loan_id,
        earn_id,
        type,
        ...(email ? { email: email } : { phone: phone }),
        ...{ newEmail: newEmail },
        ...{ newPhone: newPhone },
        captcha,
        withdrawal,
        overwrite,
        conversion,
        transfer,
      },
    })

    return {
      tokens: data.response.tokens,
      isActive2fa: data.response.is_active_2fa,
    }
  } catch (err) {
    return {
      tokens: [],
      isActive2fa: null,
      error: err?.response?.message,
    }
  }
}

export const sendVerificationCodeWithoutCaptcha = async ({
  loan_id,
  earn_id,
  phone,
  email,
  type,
  withdrawal,
  overwrite,
  conversion,
  transfer,
  action,
  new_email,
  doubleDeposit,
  refund,
}: {
  loan_id?: string
  earn_id?: string
  phone?: string
  email?: string
  new_email?: string
  type: typeof EMessageTypes[keyof typeof EMessageTypes]
  withdrawal?: Withdrawal
  refund?: Refund
  conversion?: Conversion
  doubleDeposit?: DoubleDeposit
  overwrite?: any
  transfer?: Transfer
  action?: string
}): Promise<{
  tokens: string[]
  isActive2fa: boolean
}> => {
  try {
    const { data } = await api.post('/v2/utils/verification-code/send-without-captcha', {
      params: {
        loan_id,
        earn_id,
        type,
        ...(email ? { email: email } : { phone: phone }),
        ...{ newEmail: new_email },
        withdrawal,
        refund,
        overwrite,
        conversion,
        transfer,
        action,
        doubleDeposit,
      },
    })

    return {
      tokens: data.response.tokens,
      isActive2fa: data.response.is_active_2fa,
    }
  } catch (ignore) {
    return {
      tokens: [],
      isActive2fa: null,
    }
  }
}

export const sendVerificationCodeRateLimited = async ({
  loan_id,
  earn_id,
  phone,
  email,
  newEmail,
  type,
  withdrawal,
  overwrite,
  conversion,
  user_repayment_amount,
}: {
  loan_id?: string
  user_repayment_amount?: string
  earn_id?: string
  phone?: string
  email?: string
  newEmail?: string
  type: typeof EMessageTypes[keyof typeof EMessageTypes]
  withdrawal?: Withdrawal
  conversion?: Conversion
  overwrite?: any
}): Promise<{
  tokens: string[]
  isActive2fa: boolean
}> => {
  try {
    const { data } = await api.post('/v2/utils/verification-code/send-rate-limited', {
      params: {
        loan_id,
        earn_id,
        type,
        ...(email ? { email: email } : { phone: phone }),
        ...{ newEmail: newEmail },
        user_repayment_amount,
        withdrawal,
        overwrite,
        conversion,
      },
    })
    return {
      tokens: data.response.tokens,
      isActive2fa: data.response.is_active_2fa,
    }
  } catch (err) {
    throw new Error(JSON.parse(JSON.stringify(err)))
  }
}

export const getVerifiedToken = async (params: { code: string; verification_token: string }): Promise<string> => {
  const { data } = await api.post('/v2/utils/verification-code/verify', {
    params: params,
  })

  return data.response.token
}

export const getVerifiedTokenRateLimited = async (params: {
  code: string
  verification_token: string
}): Promise<{
  result: boolean
  response: string
  message: string
}> => {
  const { data } = await api.post('/v2/utils/verification-code/verify/rate-limited', {
    params: params,
  })

  return { result: data.result, response: data.response.token, message: data.message }
}

export const getGeolocation = async (): Promise<{
  result: boolean
  response: string
}> => {
  const { data } = await api.get('/v2/utils/geolocation')
  return { result: data.result, response: data.response }
}

export const resendVerificationCode = async (params: {
  verification_token: string
  captcha: string
}): Promise<string> => {
  try {
    const { data } = await api.post('/v2/utils/verification-code/resend', {
      params: params,
    })

    return data.response.token
  } catch (ignore) {
    return params.verification_token
  }
}

export const fetchCaptcha = async (): Promise<IFetchCaptcha> => {
  try {
    const { data } = await api.get('/v2/utils/captcha')
    return data.response
  } catch (ignore) {
    return null
  }
}

export const validateAddressByNetwork = async (params: { address: string; network: string }): Promise<boolean> => {
  try {
    const { data } = await api.post('/v2/utils/validate/address', {
      params: params,
    })
    return data.result
  } catch (ignore) {
    return false
  }
}

export const validateExtraIdByNetwork = async (params: { extra_id: string; network: string }): Promise<boolean> => {
  try {
    const { data } = await api.post('/v2/utils/validate/extra-id', {
      params: params,
    })
    return data.result
  } catch (ignore) {
    return false
  }
}

export const sendMessage = async (params: { message: string; email: string }): Promise<boolean> => {
  try {
    const { data } = await api.post('/v2/utils/send-message', {
      params: params,
    })
    return data.result
  } catch (e) {
    return false
  }
}

export const _fetchSettings = async (): Promise<{
  ltv_options: string[]
  ltv_default_option: string
}> => {
  try {
    const { data } = await api.get('/v2/utils/settings')
    return data.response
  } catch (e) {
    return {
      ltv_options: [],
      ltv_default_option: null,
    }
  }
}

export const sendSlackNotification = async (params: SendSlackNotificationDto): Promise<void> => {
  try {
    await api.post('/v2/utils/report-error', { params })
  } catch (e) {
    console.error(e)
  }
}
