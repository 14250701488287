import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { _fetchCurrencies, _fetchCurrenciesWithBalance, ICurrency } from 'store/fetchers/currencies'

import { AppState, AppThunk } from '../../index'

export const storeKey = '@redux/currencies'

export interface ICurrenciesState {
  currencies: ICurrency[]
  activeCodeFrom: string
}

const initialState: ICurrenciesState = {
  currencies: [],
  activeCodeFrom: null,
}

export const currenciesSlice = createSlice({
  name: storeKey,
  initialState,
  reducers: {
    setActiveCodeFrom: (state: ICurrenciesState, action: PayloadAction<string>) => {
      state.activeCodeFrom = action.payload
    },
    setCurrencies: (state: ICurrenciesState, action: PayloadAction<ICurrency[]>) => {
      state.currencies = action.payload
    },
  },
})

export const getCurrencies = (store: AppState): ICurrency[] => store[storeKey].currencies
export const getActiveCodeFrom = (store: AppState): string => store[storeKey].activeCodeFrom
export const selectCurrency = createSelector(
  (state: AppState) => state[storeKey],
  ({ currencies }) => (ticker: string, network: string) => {
    if (!ticker || !network) return undefined

    return currencies.find(
      ({ code, network: net }) =>
        code.toLowerCase() === ticker.toLowerCase() && net.toLowerCase() === network.toLowerCase(),
    )
  },
)

export const { setActiveCodeFrom, setCurrencies } = currenciesSlice.actions

export const fetchCurrencies = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  let currencies = getCurrencies(getState())
  // if (currencies.length) {
  //   return
  // }
  try {
    currencies = await _fetchCurrenciesWithBalance()
    if (!currencies) {
      currencies = await _fetchCurrencies()
    }
  } catch (error) {}

  await dispatch(setCurrencies(currencies))
}

export default currenciesSlice.reducer
