import { ETxStatuses } from 'store/fetchers/types'

export interface IFormattedTxInfo {
  id: string
  amount: string
  hash: string
  timestamp: Date
  status: string
  incoming: boolean
}

export enum ELoanSideStatus {
  SELL = 'SELL',
  BUY = 'BUY',
}

export enum ERateSides {
  SELL = 'SELL',
  BUY = 'BUY',
}

export enum ELoanGAEvent {
  GET_LOAN = 'get_loan_button_clicked',
  LOAN_CONFIRMED = 'loan_confirmed',
  LOAN_SUCCESS = 'loan_success',
}

export enum ELoanStatuses {
  NEW = 'new', // first step only draft created
  CONFIRMED = 'confirmed', //
  CANCELLED = 'canceled', // when transaction cancelled manually
  DEPOSIT_RECEIVED = 'deposit_received', // when we detect deposit at our deposit address (core - cn-transactions-updater)
  LOAN_FAILED = 'loan_failed', // when loan transaction failed
  TRANSACTION_SENT = 'transaction_sent', // when we sent transaction to network
  LOAN_SUCCESS = 'loan_success', // when user successfully received loan
  ORDER_CREATED = 'order_created', // when we create stop-loss order on binance
  PLEDGE_REDEEMED = 'pledge_redeemed', // when user successfully sent repayment asset
  PLEDGE_TRANSACTION_SENT = 'pledge_transaction_sent', // when we successfully sent pledge back to user
  PLEDGE_SENT = 'pledge_sent', // when we sent pledge transaction to network
  CLOSED = 'closed', // when we successfully sent pledge back to user
  LIQUIDATED = 'liquidated', // when deposit has been sold
  FAILED_BY_PARTNER = 'failed_by_partner',
}

export enum EFallbackManualTxStatuses {
  NEW = 'NEW',
  RECEIVED_HASH = 'RECEIVED_HASH',
  VERIFIED = 'VERIFIED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
}

export enum EUserLoanFlow {
  EXTERNAL_WALLET = 'external_wallet',
  INTERNAL_WALLET = 'internal_wallet',
}

export interface ILoanEvent {
  id: string
  loan_id: string
  type: string
  status: string
  amount: string
  created_at: Date
  interest_percent: number
  new_repayment_amount: number
}

export interface IFormattedLoan {
  loan_id: string
  status: ELoanStatuses
  is_auto_increase_enabled: boolean
  receive_deposit_from: 'internal_wallet' | 'external_wallet'
  loan: {
    amount: string
    expected_amount: string
    currency_code: string
    currency_network: string
    receive_address: string
    receive_extra_id: string
    payout_tx: IFormattedTxInfo
    receive_to: string
    usdt_rate: string
  }
  deposit: {
    amount: string
    expected_amount: string
    currency_code: string
    currency_network: string
    rate: string
    send_address: string
    send_extra_id: string
    transaction_status: ETxStatuses
    transaction_hash: string
    active: boolean
    payin_tx: IFormattedTxInfo
    receive_from: string
    usdt_rate: string
  }
  repayment: {
    amount: string
    total_amount: string
    currency_code: string
    currency_network: string
    fee: string
    receive_address: string
    receive_extra_id: string
    transaction_status: ETxStatuses
    transaction_hash: string
    send_address: string
    send_extra_id: string
    active: boolean
    payin_txs: IFormattedTxInfo[]
    payout_tx: IFormattedTxInfo
    flow: 'internal' | 'external'
    amount_to_repayment: string
    transaction_id: string
  }
  increase: {
    amount: string
    currency_code: string
    currency_network: string
    transaction_status: ETxStatuses
    transaction_hash: string
    send_address: string
    send_extra_id: string
    active: boolean
    payin_txs: IFormattedTxInfo[]
    flow: 'internal' | 'external'
    transaction_id: string
    fallback_tx: {
      is_active: boolean
      amount: string
      send_address: string
      send_extra_id: string
      transaction_hash: string
      transaction_status: EFallbackManualTxStatuses
    }
  }
  loanEvents: ILoanEvent[]
  closed_at: string // ISO string
  liquidated_at: string // ISO string
  updated_at: string // ISO string
  created_at: string // ISO string
  interest_percent: string
  interest_amounts: {
    day: string
    month: string
    year: string
  }
  close_price: string
  liquidation_price: string
  rate_side: ERateSides
  order_in_process: boolean
  current_zone: number
  repayment_available: boolean
}

export interface LoansSummaryGroup {
  code: string
  network: string
  amount: string
}

export interface LoansSummary {
  depositAmountReducedInUsd: string
  pendingDepositAmountReducedInUsd: string
  loanAmountReducedInUsdt: string
  repaymentAmountReducedInUsdt: string
  count: number
  groups: LoansSummaryGroup[]
}

export enum EExchangeDirections {
  direct = 'direct',
  reverse = 'reverse',
}

export interface IExchangeData {
  one_month_fee: string
  amount_from: string
  amount_to: string
  interest_percent: string
  interest_amounts: {
    year: string
    month: string
    day: string
  }
  down_limit: string
  precision: number
}

export enum FormattedLoanStatus {
  CANCELED = 'Canceled',
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  LIQUIDATED = 'Liquidated',
}

export interface ITotalAmountOfLoans {
  count: number
  depositAmountReducedInUsd: string
  pendingAmountReducedInUsd: string
  groups: [
    {
      amount: string
      code: string
      network: string
      pendingAmount: string
    },
  ]
}
