import { rules } from '@coxy/react-validator'
import isValidAmount from 'utils/is-valid-amount'
import { ICurrency, ICurrencyWithBalance } from 'store/fetchers/currencies'

import { formatNumber } from '../utils/formatters'

export type IRules = Array<{ rule: (value: string) => boolean; message: string }>

export default {
  exchangeDepositAmount: (from: ICurrencyWithBalance): IRules => [
    {
      rule: (amount) => amount !== '',
      message: 'Deposit amount is required',
    },
    {
      rule: (val) => isValidAmount(parseFloat(val)),
      message: 'Collateral amount is required',
    },
    // {
    //   rule: (val) => {
    //     if (from?.loan_deposit_min_amount) {
    //       return Number(val) >= Number(from?.loan_deposit_min_amount)
    //     }
    //     return true
    //   },
    //   message: `Minimum amount is ${from?.loan_deposit_min_amount} ${from?.code}`,
    // },
    {
      rule: (val) => {
        if (from?.balance >= 0) {
          return Number(val) <= Number(from?.balance || 0)
        }
        return true
      },
      message: `Not enough balance for exchange.`,
    },
  ],

  depositAmount: (from: ICurrencyWithBalance): IRules => [
    {
      rule: (amount) => amount !== '',
      message: 'Deposit amount is required',
    },
    {
      rule: (val) => isValidAmount(parseFloat(val)),
      message: 'Collateral amount is required',
    },
    {
      rule: (val) => {
        if (from?.loan_deposit_max_amount) {
          return Number(val) <= Number(from?.loan_deposit_max_amount)
        }
        return true
      },
      message: `Maximum amount is ${formatNumber(from?.loan_deposit_max_amount)} ${from?.code}`,
    },
  ],

  loanAmount: (to: ICurrency): IRules => [
    {
      rule: (amount) => amount !== '',
      message: 'Loan amount is required',
    },
    {
      rule: (val) => isValidAmount(parseFloat(val)),
      message: 'Loan amount is invalid',
    },
    {
      rule: (val) => {
        if (to?.loan_receive_min_amount) {
          return Number(val) >= Number(to?.loan_receive_min_amount)
        }
        return true
      },
      message: `Minimum amount is ${formatNumber(to?.loan_receive_min_amount)} ${to?.code}`,
    },
    {
      rule: (val) => {
        if (to?.loan_receive_max_amount) {
          return Number(val) <= Number(to?.loan_receive_max_amount)
        }
        return true
      },
      message: `Maximum amount is ${formatNumber(to?.loan_receive_max_amount)} ${to?.code}`,
    },
  ],

  increaseDepositEarn: (max: number, coin: string): IRules => [
    {
      rule: (value) => value !== '',
      message: 'Increase amount is required',
    },
    {
      rule: isValidAmount,
      message: 'Increase amount is invalid',
    },
    {
      rule: (value) => Number(value) >= 100,
      message: `Minimum amount for deposit is 100 ${coin}`,
    },
    {
      rule: (amount) => Number(amount) <= max,
      message: `Maximum amount for increase is ${formatNumber(max)} ${coin}`,
    },
  ],

  inputWallet: (): IRules => [
    {
      rule: (value) => value !== '',
      message: 'Deposit amount is required',
    },
    {
      rule: isValidAmount,
      message: 'Deposit amount is invalid',
    },
    {
      rule: (value) => {
        const numericValue = Number(value)
        return !isNaN(numericValue) && numericValue > 0
      },
      message: 'Increase your deposit amount',
    },
  ],

  sendWallet: (balance: number): IRules => [
    {
      rule: (value) => value !== '',
      message: 'The amount is required',
    },
    {
      rule: isValidAmount,
      message: 'The amount is invalid',
    },
    {
      rule: (value) => {
        const numericValue = Number(value)
        return !isNaN(numericValue) && numericValue > 0
      },
      message: 'The amount must be greater than 0',
    },
    {
      rule: (value) => {
        const numericValue = Number(value)
        return !isNaN(numericValue) && balance >= numericValue
      },
      message: 'Insufficient funds',
    },
  ],

  increaseDeposit: (max: string, from: ICurrency): IRules => [
    {
      rule: (amount) => amount !== '',
      message: 'Increase amount is required',
    },
    {
      rule: isValidAmount,
      message: 'Increase amount is invalid',
    },
    {
      rule: (amount) => !max || Number(amount) <= Number(max),
      message: `Maximum amount for increase is ${formatNumber(max)} ${from?.code}`,
    },
  ],

  withdrawal: (max: string, coin: string): IRules => [
    {
      rule: (amount) => amount !== '',
      message: 'Withdrawal amount is required',
    },
    {
      rule: isValidAmount,
      message: 'Withdrawal amount is invalid',
    },
    {
      rule: (value) => Number(value) <= Number(max),
      message: `Maximum amount for withdrawal is ${formatNumber(max)} ${coin}`,
    },
  ],

  email: rules.email,

  bool: (alias = 'Value'): IRules => [
    {
      rule: (value) => !!value,
      message: `${alias} is required`,
    },
  ],

  earn: (currency: ICurrency): IRules => [
    {
      rule: (amount) => amount !== '',
      message: 'Deposit amount is required',
    },
    {
      rule: (val) => {
        if (currency?.earn_min_amount) {
          return Number(val) >= Number(currency.earn_min_amount)
        }
        return true
      },
      message: `Minimum amount is ${formatNumber(currency?.earn_min_amount)}`,
    },
  ],
}
